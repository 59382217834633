//ion-item::part(native) {
//  margin-left: 0px;
//  padding-left: 0px;
//  padding-right: 0px;
//
//  .item-inner {
//    padding-right: 0px;
//  }
//}
//
//ion-time.item-inner::part(native) {
//  margin-left: 0px !important;
//  padding-right: 0px;
//}

.personality-card {
  &:hover {
    background: black;
    color: white;
  }

  &__selected {
    background: black;
    color: white;
  }
}


.new-date {
  &__saving {
    display: flex;
    align-items: center;
    justify-content: center;
    flex: 1;
    padding: 50px;
    min-height: 300px;
  }
}